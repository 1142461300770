import React, {useState } from "react";
import Input from "../components/input";
import Pass from "../assets/password.png";
import Button from "../components/button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PPLOGO from "../assets/logoo.png";
import * as yup from "yup";
import NotificationForm from "../components/notificationForm";
const validationSchema = yup.object().shape({
  password: yup
    .number()
    .min(4, "Passcode must be at least 4 characters long")
    .required("Passcode is required"),
});
export default function Notifications() {
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [shown, setShown] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);

      try {
        if (values.password === "7788") {
          toast.success("Validation Successful!");
          setTimeout(() => {
            setShown(true);
          }, 2000);
          setLoader(false);
        } else {
          toast.error("Invalid Passcode");
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log("error", error);
        toast.error(error.response.data.message);
      }
    },
  });

  const displayPassword = () => {
    setShowPassword(!showPassword);
  };
 
  return (
    <>
      <div className="grid lg:grid-cols-1  md:grid-cols-1 grid-cols-1  gap-0">
        {shown ? (
          <NotificationForm />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="bg-[#FAFAFA] h-[100vh] ">
              <div className="h-full flex items-center justify-center">
                <div>
                  <div className="flex justify-center ">
                    <img src={PPLOGO} alt="" width={260} height={24} />
                  </div>

                  <p className="text-[#2A2C3E] text-sm font-thin text-center font-poppins pt-6">
                    Enter passcode to access the Notification Screen
                  </p>

                  <div className="pt-2">
                    <div className="flex justify-start items-center font-normal text-[16px] text-[#2A2C3E] pt-2">
                      <p>Passcode</p>
                    </div>
                    <div className="flex justify-start items-center font-medium text-3xl pt-0 relative">
                      <Input
                        value={formik.values.password}
                        text={"*********"}
                        source={Pass}
                        name="password"
                        id="password"
                        type="password"
                        onChange={formik.handleChange}
                        point={true}
                        showPassword={showPassword}
                        displayPassword={displayPassword}
                        className="bg-[#F4F2F2] w-[350px] px-2  h-10 text-sm rounded-md text-black"
                      />
                    </div>
                    {formik.errors.password && formik.touched.password && (
                      <p className="text-[10px] text-red-600 text-left mt-1 w-80">
                        {formik.errors.password}
                      </p>
                    )}
                    <div className="flex justify-center items-center font-medium text-3xl pt-2 relative">
                      <Button
                        text={"Submit"}
                        className={
                          "w-[350px] text-white bg-black text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED]"
                        }
                        type="submit"
                        setLoader={setLoader}
                        loader={loader}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
