import React, { useEffect, useState } from "react";
import Input from "../components/input";
import Mail from "../assets/mail.png";
import Pass from "../assets/password.png";
import Button from "../components/button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import bg from "../assets/bg.png";
import PPLOGO from "../assets/logoo.png";
import * as yup from "yup";
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is Required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
});
export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  let user = localStorage.getItem("loggedInUser");
  let loggedInUser = JSON.parse(user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);

      try {
        if (
          values.email === "library@gmail.com" &&
          values.password === "hello123"
        ) {
          localStorage.setItem("loggedInUser", JSON.stringify(true));

          toast.success("LoggedIn Successfully!");
          setTimeout(() => {
            navigate("/voices");
          }, 2000);
          setLoader(false);
        } else {
          toast.error("Invalid credential!");
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log("error", error);
        toast.error(error.response.data.message);
      }
    },
  });

  const displayPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleUser = () => {
    navigate("/voices");
  };

  useEffect(() => {
    if (loggedInUser) {
      navigate("/voices");
    }
  }, []);

  return (
    <>
      <div className="grid lg:grid-cols-2  md:grid-cols-2 grid-cols-1  gap-0">
        <div className="bg-black h-[100vh] justify-center  lg:flex md:flex hidden">
          <div className="h-full flex items-center justify-center">
            <div>
              <div className="flex justify-center items-center">
                <img alt="" src={bg} width={400} height="100%" />
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-[#FAFAFA] h-[100vh] ">
            <div className="h-full flex items-center justify-center">
              <div>
                <div className="flex justify-center ">
                  <img src={PPLOGO} alt="" width={260} height={24} />
                </div>
                <p className="text-[#2A2C3E] text-sm font-thin text-center font-poppins pt-6">
                  Login to your account below
                </p>
                <div className="pt-2">
                  <div className="flex justify-start items-center font-normal text-[16px] text-[#2A2C3E] pt-2">
                    <p>Email</p>
                  </div>
                  <div className="flex justify-start items-center font-medium text-3xl pt-0  relative">
                    <Input
                      text={"johndoe@gmail.com"}
                      source={Mail}
                      name="email"
                      pointe={true}
                      id="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      type="email"
                      className="bg-[#F4F2F2] w-[350px] px-2  h-10 text-sm rounded-md text-black"
                    />
                  </div>
                  {formik.errors.email && formik.touched.email && (
                    <p className="text-[10px] text-red-600 text-left mt-1">
                      {formik.errors.email}
                    </p>
                  )}
                  <div className="flex justify-start items-center font-normal text-[16px] text-[#2A2C3E] pt-2">
                    <p>Password</p>
                  </div>
                  <div className="flex justify-start items-center font-medium text-3xl pt-0 relative">
                    <Input
                      value={formik.values.password}
                      text={"*********"}
                      source={Pass}
                      name="password"
                      id="password"
                      type="password"
                      onChange={formik.handleChange}
                      point={true}
                      showPassword={showPassword}
                      displayPassword={displayPassword}
                      className="bg-[#F4F2F2] w-[350px] px-2  h-10 text-sm rounded-md text-black"
                    />
                  </div>
                  {formik.errors.password && formik.touched.password && (
                    <p className="text-[10px] text-red-600 text-left mt-1">
                      {formik.errors.password}
                    </p>
                  )}
                  <div className="flex justify-center items-center font-medium text-3xl pt-2 relative">
                    <Button
                      text={"Login"}
                      className={
                        "w-[350px] text-white bg-black text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED]"
                      }
                      type="submit"
                      setLoader={setLoader}
                      loader={loader}
                    />
                  </div>
                  <p
                    className="text-[#2A2C3E] cursor-pointer text-sm font-thin text-center font-poppins pt-6 underline hover:text-blue-700"
                    onClick={handleUser}
                  >
                    Guest User ?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
