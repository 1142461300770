import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import setting from "../assets/setting.png";
import Button from "../components/button";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { faMicrophone, faRecordVinyl } from "@fortawesome/free-solid-svg-icons";
import { Ellipsis } from "react-awesome-spinners";
import Pilot from "../assets/pilot.png";
import axios from "axios";

function Voices() {
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [transcribedText, setTranscribedText] = useState("");
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [token, setToken] = useState("");
  console.log("audioUrl", audioUrl);
  console.log("transcribedText", transcribedText);
  let user = localStorage.getItem("loggedInUser");
  let loggedInUser = JSON.parse(user);
  const recognition = useRef(null);

  const handleLogout = () => {
    localStorage.removeItem("loggedInUser");
    navigate("/");
  };
  const handleVoices = () => {
    navigate("/voices");
  };
  const handleNotifications = () => {
    navigate("/notification");
  };

  const handleQueries = () => {
    navigate("/queries");
  };

  const handleStartRecording = () => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      recognition.current = new SpeechRecognition();

      recognition.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setTranscribedText(transcript);
      };

      recognition.current.onend = () => {
        setIsRecording(false);
      };

      recognition.current.start();
      setIsRecording(true);
    } else {
      alert("Speech recognition is not supported in this browser.");
    }
  };
  const handleStopRecording = () => {
    if (recognition.current) {
      recognition.current.stop();
      setIsRecording(false);
    }
  };
  const handleAudioEnded = () => {
    setAudioUrl("");
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.get(
            "https://vbas5p84xi.execute-api.us-east-2.amazonaws.com/production/createToken",
        ); // Replace with your API endpoint
        setToken(response.data);
      } catch (error) {}
    };
    fetchToken()
  }, []);

  useEffect(() => {
    if (transcribedText) {
      setIsLoading(true);
      fetch(`https://vbas5p84xi.execute-api.us-east-2.amazonaws.com/production/postQueryToChatbot`, {
        mode: 'cors',
        method: 'POST',
        headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({ question: transcribedText }) // Assuming you want to send the transcribedText in a JSON object
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse JSON response into JavaScript object
      })
      .then(response => {
        // console.log("Post request successful:", JSON.stringify(response));
        setAudioUrl(response.body.audioLink);
        setText(response.body.answer);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error making post request:", error);
        setIsLoading(false);
      });
    }
  }, [transcribedText]);
  

  return (
    <>
      {loggedInUser && (
        <>
          <div className="flex justify-center sm:justify-end">
            <div className="flex justify-between">
              <div
                className="w-[80px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 sm:mr-6 p-2 font-semibold flex justify-center items-center"
                onClick={handleQueries}
              >
                <img
                  src={setting}
                  width={24}
                  height={24}
                  onClick={handleQueries}
                />
              </div>
              <div
                className="w-[80px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 sm:mr-6 p-2 font-semibold flex justify-center items-center"
                onClick={handleNotifications}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  color="white"
                  className="h-[22px] w-[22px]"
                />
              </div>
              <div
                className="w-[80px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 sm:mr-6 p-2 font-semibold flex justify-center items-center"
                onClick={handleVoices}
              >
                <FontAwesomeIcon
                  icon={faCommentAlt}
                  color="white"
                  className="h-[22px] w-[22px]"
                />
              </div>
              <Button
                handleClick={handleLogout}
                text={"Logout"}
                className={
                  "w-[150px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 p-2 font-semibold"
                }
              />
            </div>
          </div>
        </>
      )}

      <div className="grid justify-center items-center h-screen">
        {audioUrl ? (
          <div className="flex justify-between items-center">
            <div className="text-[#bcb8c2] relative flex justify-center items-center">
              <div className="animate-blink absolute h-[280px] w-72 rounded-full border-4 border-[#8948ED] flex justify-center items-center"></div>
              <div className="animate-tlink absolute h-[330px] w-80 rounded-full border-4 border-[#8948ED] flex justify-center items-center"></div>
              <div className="animate-blink absolute h-[360px] w-96 rounded-full border-4 border-[#8948ED] flex justify-center items-center"></div>
              <video autoPlay loop muted className="w-[300px] h-[288px]">
                <source
                  src="https://ai-avatar-video.s3.us-east-2.amazonaws.com/smile.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="text-white w-96 pl-20 ">
              <p className="text-justify animate-fade-in font-poppins">
                {text}
              </p>
            </div>
          </div>
        ) : (
          <div className="text-[#bcb8c2] relative flex justify-center items-center">
            <img src={Pilot} alt="" className="rounded-full w-80 h-76" />
          </div>
        )}

        {isLoading && (
          <div className="loader flex justify-center items-center">
            <Ellipsis color="white" />
          </div>
        )}
        <div className="mt-[-30px]">
          {!isRecording ? (
            <div
              className="text-[#bcb8c2] relative flex justify-center items-center cursor-pointer"
              onClick={handleStartRecording}
            >
              <div className="animate-blink absolute h-32 w-32 rounded-full border-4 border-[#8948ED] flex justify-center items-center"></div>
              <div className="animate-tlink absolute h-24 w-24 rounded-full border-4 border-[#8948ED] flex justify-center items-center"></div>

              <FontAwesomeIcon
                icon={faMicrophone}
                className="text-7xl flex justify-center items-center "
              />
            </div>
          ) : (
            <div
              className="text-[#bcb8c2] relative flex justify-center items-center cursor-pointer"
              onClick={handleStopRecording}
            >
              <div className="animate-blink absolute h-32 w-32 rounded-full border-4 border-[#8948ED] flex justify-center items-center"></div>
              <div className="animate-tlink absolute h-24 w-24 rounded-full border-4 border-[#8948ED] flex justify-center items-center"></div>

              <FontAwesomeIcon
                icon={faRecordVinyl}
                className="text-7xl flex justify-center items-center "
              />
            </div>
          )}
        </div>
      </div>
      <div className="hidden">
        {audioUrl && (
          <audio key={audioUrl} controls autoPlay onEnded={handleAudioEnded}>
            <source src={audioUrl} type="audio/wav" />
          </audio>
        )}
      </div>
    </>
  );
}

export default Voices;
