import React from "react";
// import Image from "next/image";
export default function Input({
  disabled,
  text,
  source,
  name,
  id,
  onChange,
  type,
  point,
  showPassword,
  displayPassword,
  className,
  value,
  pointe,
}) {
  return (
    <div>
      <input
        value={value}
        disabled={disabled}
        placeholder={text}
        name={name}
        id={id}
        onChange={onChange}
        type={showPassword ? "text" : type}
        className={className}
      />
      {point || pointe ? (
        <img
          src={source}
          width={15}
          height={1}
          alt=""
          onClick={displayPassword}
          className={
            point
              ? " absolute right-2 top-[18px] cursor-pointer"
              : " absolute right-2 top-[18px]"
          }
        />
      ) : null}
    </div>
  );
}
