import React from "react";
import Login from "./pages/login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Bot from "./pages/bot";
import Queries from "./pages/queries";
import AuthGuard from "./components/authGuard";
import Alerts from "./components/alerts";
import Voices from "./pages/voices";
import Notifications from "./pages/notifications";
function App() {
  return (
    <BrowserRouter>
      <Alerts />
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route
          path="/bot"
          element={
            <AuthGuard>
              <Bot />
            </AuthGuard>
          }
        /> */}
        <Route
          path="/queries"
          element={
            <AuthGuard>
              <Queries />
            </AuthGuard>
          }
        />
        <Route
          path="/notification"
          element={
            <AuthGuard>
              <Notifications />
            </AuthGuard>
          }
        />
        <Route
          path="/voices"
          element={
            // <AuthGuard>
            <Voices />
            // </AuthGuard>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
