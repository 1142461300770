import React from "react";
import { Ellipsis } from "react-awesome-spinners";
import Loader from "./loader";

export default function Button({
  type,
  style,
  className,
  text,
  linear,
  handleClick,
  loader,
  disabled,
}) {
  console.log("loader", loader);
  return (
    <>
      <div
        style={{
          background: linear,
        }}
        onClick={handleClick}
        className={className}
      >
        <button
          style={{ width: "100%", height: linear ? "44px" : "38px" }}
          type={type}
          disabled={loader}
        >
          {text}
        </button>
      </div>
      {loader && <Loader />}
    </>
  );
}
