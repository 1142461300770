import React, { useEffect, useState } from "react";
import Input from "../components/input";
import Image1 from "../assets/image.png";
import Mail from "../assets/mail.png";
import Pass from "../assets/password.png";
import Button from "../components/button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import bg from "../assets/bg.png";
import SLogo from "../assets/S.png";
import PPLOGO from "../assets/logoo.png";
import * as yup from "yup";
import axios from "axios";
const validationSchema = yup.object().shape({
  title: yup.string().required("Email is Required"),
  body: yup
    .string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
});
export default function NotificationForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  let user = localStorage.getItem("loggedInUser");
  let loggedInUser = JSON.parse(user);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      body: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   setLoader(true);
      console.log("values", values.body);

      try {
        const apiUrl = "https://getallentries-ljvwcw7toq-uc.a.run.app/";
        const data = {
          title: values.title,
          body: values.body,
        };
        const headers = {
          "Content-Type": "application/json",
        };

        const response = await axios.post(apiUrl, data, { headers: headers });

        console.log("🚀 ~ onSubmit: ~ response:", response);

        if (response.status === 200) {
          toast.success("Notification sent successfully!");
          setTimeout(() => {
            navigate("/voices");
          }, 2000);
        } else {
          toast.error("Error in login");
        }
      } catch (error) {
        toast.error("Invalid credential!");

        setLoader(false);

        console.log("error", error);
        toast.error(error.response.data.message);
      }
    },
  });

  return (
    <>
      {/* <div className="grid lg:grid-cols-1  md:grid-cols-1 grid-cols-1  gap-0"> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-[#FAFAFA] h-[100vh] ">
          <div className="h-full flex items-center justify-center">
            <div>
              <div className="flex justify-center ">
                <img src={PPLOGO} alt="" width={260} height={24} />
              </div>
              <div className="pt-2">
                <div className="flex justify-start items-center font-normal text-[16px] text-[#2A2C3E] pt-2">
                  <p>Title</p>
                </div>
                <div className="flex justify-start items-center font-medium text-3xl pt-0  relative">
                  <Input
                    text={"Enter Title"}
                    name="title"
                    //   pointe={true}
                    id="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    type="text"
                    className="bg-[#F4F2F2] w-[350px] px-2  h-10 text-sm rounded-md text-black"
                  />
                </div>
                {formik.errors.title && formik.touched.title && (
                  <p className="text-[10px] text-red-600 text-left mt-1">
                    {formik.errors.title}
                  </p>
                )}
                <div className="flex justify-start items-center font-normal text-[16px] text-[#2A2C3E] pt-2">
                  <p>Body</p>
                </div>
                <div className="flex justify-start items-center font-medium text-3xl pt-0 relative">
                  <textarea
                    id="body"
                    value={formik.values.body}
                    onChange={formik.handleChange}
                    rows={4} // Specify the number of visible text lines
                    cols={50} // Specify the number of visible text columns
                    placeholder="Enter Body" // Placeholder text
                    className="bg-[#F4F2F2] w-[350px] px-2 py-2 text-sm rounded-md mt-2 text-black"
                  />
                  {/* <Input
                      value={formik.values.body}
                      text={"Enter body"}
                      //   source={Pass}
                      name="body"
                      id="body"
                      type="body"
                      point={true}
                      className="bg-[#F4F2F2] w-[350px] px-2  h-10 text-sm rounded-md text-black"
                    /> */}
                </div>
                {formik.errors.body && formik.touched.body && (
                  <p className="text-[10px] text-red-600 text-left mt-1">
                    {formik.errors.body}
                  </p>
                )}
                <div className="flex justify-center items-center font-medium text-3xl pt-2 relative">
                  <Button
                    text={"Send"}
                    className={
                      "w-[350px] text-white bg-black text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED]"
                    }
                    type="submit"
                    setLoader={setLoader}
                    loader={loader}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* </div> */}
    </>
  );
}
