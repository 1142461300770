import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Login from "../pages/login";

const AuthGuard = (props) => {
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  let user = localStorage.getItem("loggedInUser");
  let loggedInUser = JSON.parse(user);
  if (!loggedInUser) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{props.children}</>;
};
export default AuthGuard;
