import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import setting from "../assets/setting.png";
import Button from "../components/button";
import axios from "axios";
import { toast } from "react-toastify";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";


function Queries() {
  const navigate = useNavigate();
  const [data, setData] = useState([{}]);
  const [content, setContent] = useState("");
  const [token, setToken] = useState("")
  console.log("data", data);

  const handleTextAreaChange = (event) => {
    setContent(event.target.value);
  };

  const handleButtonClick = () => {
    fetch(`https://vbas5p84xi.execute-api.us-east-2.amazonaws.com/production/updateKnowledgeBase`, {
      method: 'POST',
      headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json', 'Accept':'application/json'},
      body: JSON.stringify({ knowledgeBase: `${content}` })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log("Post request successful:", data);
      toast.success("Content Updated Successfully!");
    })
    .catch(error => {
      console.error("Error making post request:", error);
    });
  };
  
  const handleLogout = () => {
    localStorage.removeItem("loggedInUser");
    navigate("/");
  };
  const handleVoices = () => {
    navigate("/voices");
  };
  const handleNotifications = () => {
    navigate("/notification");
  };

  const handleQueries = () => {
    navigate("/queries");
  };

  const downloadCSV = () => {
    // Convert data to CSV format
    const csvData = data.map((row) => row.question).join("\n");

    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "table_data.csv";

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.get(
            "https://vbas5p84xi.execute-api.us-east-2.amazonaws.com/production/createToken",
        ); // Replace with your API endpoint
        setToken(response.data);
      } catch (error) {}
    };
    fetchToken();
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      if (token !== "") {
        try {
          const response = await axios.get(
              "https://vbas5p84xi.execute-api.us-east-2.amazonaws.com/production/getKnowledgeBase",
              {
                headers: {
                  "Authorization": `${token}`
                }
              }
          ); // Replace with your API endpoint
          setContent(response.data);
        } catch (error) {
        }
      }
    }
    fetchData();
  },[token]);
  useEffect(() => {
    if (token !== "") {
      const apiUrl = "https://vbas5p84xi.execute-api.us-east-2.amazonaws.com/production/getQuestions";

      axios.get(apiUrl, {
        headers: {
          'Authorization': `${token}`
        }
      })
          .then((response) => {
            // Assuming 'response.data' is the object structure you've shown
            setData(response.data.body); // Directly use the 'body' part which is the array of questions
          })
          .catch((error) => console.error("Axios error:", error));
    }
  }, [token]);


  const TableComponent = () => {
    return (
      <div className="h-56 overflow-x-scroll w-full">
        <table className="border-collapse w-full">
          <thead>
            <tr>
              <th className="border border-gray-400 px-4 py-2">Questions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => (
              <tr key={row.id}>
                <td className="border border-gray-400 px-4 py-2">
                  {row?.question}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-center sm:justify-end">
        <div className="flex justify-between">
          <div
            className="w-[80px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 sm:mr-6 p-2 font-semibold flex justify-center items-center"
            onClick={handleQueries}
          >
            <img src={setting} width={24} height={24} onClick={handleQueries} />
          </div>
          <div
            className="w-[80px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 sm:mr-6 p-2 font-semibold flex justify-center items-center"
            onClick={handleNotifications}
          >
            <FontAwesomeIcon
              icon={faBell}
              color="white"
              className="h-[22px] w-[22px]"
            />
          </div>
          <div
            className="w-[80px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 sm:mr-6 p-2 font-semibold flex justify-center items-center"
            onClick={handleVoices}
          >
            <FontAwesomeIcon
              icon={faCommentAlt}
              color="white"
              className="h-[22px] w-[22px]"
            />
          </div>
          <Button
            handleClick={handleLogout}
            text={"Logout"}
            className={
              "w-[150px] text-white bg-[#0F0F0F] text-center mt-10 text-lg rounded-xl cursor-pointer hover:bg-[#8948ED] mr-10 p-2 font-semibold"
            }
          />
        </div>
      </div>
      <div className="flex justify-center ">
        <div className="w-full max-w-md px-2 py-16 sm:px-0 ">
          <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-[#0F0F0F] p-1 ">
              {["Questions", "Content"].map((tabName, tabIndex) => (
                <Tab
                  key={tabIndex}
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-[#8948ED] focus:outline-none focus:ring-2 ${
                      selected
                        ? "bg-white text-[#8948ED] shadow font-poppins"
                        : "text-white hover:bg-white/[0.12] hover:text-white font-poppins"
                    }`
                  }
                >
                  {tabName}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2 ">
              <Tab.Panel
                key="Table"
                className="rounded-xl bg-white p-3 ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 h-[296px] "
              >
                <TableComponent />
                <div className="flex justify-center">
                  <button
                    className="bg-[#0F0F0F] text-white px-4 py-2 rounded-md hover:bg-[#8948ED] font-poppins mt-2"
                    onClick={downloadCSV}
                  >
                    Download
                  </button>
                </div>
              </Tab.Panel>
              <Tab.Panel
                key="Text & Button"
                className="rounded-xl bg-white p-3 ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 h-72"
              >
                <div className="flex flex-col items-center">
                  <textarea
                    className="border border-gray-400 rounded-md p-2 mb-3 w-full "
                    rows={8}
                    placeholder="Enter text here..."
                    value={content}
                    onChange={handleTextAreaChange}
                  />
                  <button
                    className="bg-[#0F0F0F] text-white px-4 py-2 rounded-md hover:bg-[#8948ED] font-poppins"
                    onClick={handleButtonClick}
                  >
                    Update
                  </button>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
}

export default Queries;
